import React, { useState, useRef, useEffect } from 'react';
import style from './FAQItem.module.css';
import { FAQItemIcon } from './FAQItemIcon';
import { QuestionType } from '@/components/PagesSections/FAQPageComponents/FAQQuestionsVariants';
import { NextRouter } from 'next/router';
import styled from 'styled-components';
import { Typography } from '@/components/Typography/Typography';
import { clsx } from 'clsx';

type FAQItemProps = {
  isMobile?: boolean;
  rememberOpen?: boolean;
  item: QuestionType;
  router?: NextRouter;
  className?: string;
};

const StyledQuestion = styled.button({
  '@media (max-width: 768px)': {
    padding: '30px 20px',
  },
});

const StyledChip = styled.div({
  '@media (max-width: 768px)': {
    margin: '20px 0px !important',
  },
});

export const FAQItem = ({
  isMobile = false,
  item,
  router,
  rememberOpen,
  className = '',
}: FAQItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  const { push, pathname, query } = router as NextRouter;

  useEffect(() => {
    if (bodyRef && bodyRef.current) {
      // @ts-ignore
      bodyRef.current.style.height = expanded
        ? // @ts-ignore
          bodyRef?.current?.firstChild?.clientHeight + 'px'
        : 0;
    }
  }, [expanded]);

  useEffect(() => {
    if (query?.expanded) {
      setExpanded(JSON.parse(query?.expanded as string).includes(item.id));
    } else {
      setExpanded(false);
    }
  }, [query]);

  const handleExpand = () => {
    setExpanded((prevState) => !prevState);

    if (rememberOpen) {
      const expandedIds = query?.expanded ? JSON.parse(query?.expanded as string) : [];

      const updatedIds = !expanded
        ? [...expandedIds, item.id]
        : expandedIds?.filter((id: string) => Number(id) !== item.id);

      const queryParams = {
        ...query,
        expanded: JSON.stringify(updatedIds),
      };

      push(
        {
          pathname: pathname,
          query: queryParams,
        },
        undefined,
        { scroll: false }
      );
    }
  };

  return (
    <StyledQuestion
      className={clsx(
        `question ${isMobile ? style.mobile : style.desktop} ${style.faqitem} ${expanded ? style.expanded : ''}`,
        className
      )}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleExpand();
        }
      }}
    >
      <div className={style.faqheader} onClick={handleExpand}>
        <div className="title">
          {Boolean(!!item?.chips?.length) && (
            <div className="chips desktop">
              {item?.chips?.map((chip, index) => (
                <div key={`${item?.id}-${index}`} className={`chip ${chip?.color}`}>
                  {chip?.name}
                </div>
              ))}
            </div>
          )}
          <div>
            <Typography
              as="h3"
              $type={'Heading4'}
              $weight="600"
              className="font-jamjuree text-left"
            >
              {item.question}
            </Typography>
          </div>
        </div>
        <div className={style.iconContainer}>
          <FAQItemIcon />
        </div>
      </div>
      <div ref={bodyRef} className={style.faqbody}>
        {item.answer}
      </div>
      {Boolean(!!item?.chips?.length) && (
        <StyledChip className="chips mobile">
          {item?.chips?.map((chip, index) => (
            <div key={`${item?.id}-${index + 1}`} className={`chip ${chip?.color}`}>
              {chip?.name}
            </div>
          ))}
        </StyledChip>
      )}
    </StyledQuestion>
  );
};
